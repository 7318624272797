.np-header{padding-top: 40px;}
.np-section{margin: 50px 0 100px 0;}
.np-section__inner{display: flex;justify-content: center;align-items: center;flex-direction: column;}
.np-sectionTxt{margin-top: 50px;}
.np-sectionTxt h3{font-size: 48px;color: var(--color-white);font-weight: var(--fw-regular);margin-bottom: 10px;}
.np-sectionTxt p{font-size: 24px;color: var(--color-white);font-weight: var(--fw-regular);}

@media screen and (max-width:1700px){
  .home-wrapper{max-width:1640px;width:100%;padding:0 15px;margin:0 0 0 auto;}
}
@media screen and (max-width:1650px){
  .np-sectionImg img{width:75%;margin:0 auto;}
}
@media screen and (max-width:1600px){
  .home-wrapper{max-width:1580px;width:100%;padding:0 15px;margin:0 0 0 auto;}
}
@media screen and (max-width:1536px){
  .home-wrapper{max-width:1500px;width:100%;padding:0 15px;margin:0 0 0 auto;}
}

@media screen  and (max-width:1500px){
  .home-wrapper{max-width:1450px;width:100%;padding:0 15px;margin:0 0 0 auto;}
}

@media screen and (max-width:1400px){
  .np-section{margin:25px 0 25px 0;}
  .np-sectionImg img{width:60%;margin:0 auto;}
  .np-sectionTxt{margin-top:25px;}
  .np-sectionTxt h3{font-size: 36px;}
  .np-sectionTxt p{font-size: 20px;}
}
@media screen and (max-width:1300px){

}
@media screen and (max-width:1140px){

}

@media screen and (max-width:992px){

}
@media screen and (max-width:740px){

}
@media screen and (max-width:600px){
  .np-sectionTxt h3{font-size: 26px;}
  .np-sectionTxt p{font-size: 18px;}
  .np-header{padding-top:20px;}
  .np-sectionImg img{width:80%;margin:0 auto;}


}

@media screen and (max-width:428px) {
  .np-sectionTxt h3{font-size: 20px;}
  .np-sectionTxt p{font-size: 16px;}
}